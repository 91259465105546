.main {
  height: 30rem;
  width: 100%;
  overflow: hidden;
  margin-top: -1rem;
}
@media screen and (max-width: 1089px) {
  html {
    font-size: 12px;
    margin-top: 5rem;
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .main {
    height: max-content;
    width: 100%;
  }
}
