.main{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 2rem;
}
/* ThankYouPageSectionSection.module.css */


  
  .thank_you {
    border: 1px #f5e3e3 solid;
    padding: 20px;
    border-radius: 5px;
    width: 100%;
    font-family: "Jost", sans-serif;
  }
  .order_details{
    width: 100%;
  }
  .thank_you h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .order_details {
    margin-top: 20px;
  }
  
  .order_details h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  

  strong {
    font-weight: bold;
  }
  
  .btn_ {
    display: flex;
    align-items: center;
    border: none;
    border: 1px #8d5f5f solid;
    padding: 0.5rem 1rem;
    font-size: small;
    letter-spacing: 0.3cap;
    background-color: white;
    width: max-content;
  }
  .btn_:hover {
    background-color: #7b0128;
    color: white;
  }
  .link{
color: black;
  }
  .order_item {
    display: flex;
    justify-content: space-between;
    padding: 0rem 1rem;
    align-items: center;
    border-bottom: 1px #7b0128 solid;
  }
  .product_item {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0rem;
    align-items: center;
    width: 80%;
  }
  .header {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0rem;
    align-items: center;
    font-weight: 500;
    width: 80%;
  }
  .order_summary{
    width: 100%;
  }