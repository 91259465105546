.icon_box4 {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0rem 1rem;
  cursor: pointer;
}
.icon_box4 img {
  height: 1rem;
  width: 2rem;
}
.list {
  padding: 2rem;
}
.list a{
    color: black;
    cursor: pointer;
}

.list p{
    font-size: 2rem!important;
}

.closebtn{
     position: absolute;
     right: 7%;
     border: none;
     margin-top: 1.5rem;
     background-color: none;
     background: transparent;
}
.header_box{
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0rem 1.5rem;
}
.img_box{
  width: 8rem;
   height: 5rem;
}
.img_box img {
  height: 100%;
  width: 100%;
}
.Option_lists{
  padding: 1.6rem;
}
.Option_lists a{
   color: black!important;
}