.carousel_box {
  height: 30rem;
  width: 100%;
}
.inner_container_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30rem;
}
.inner_container_ h2 {
  font-size: 2.5rem;
}
.img_box {
  height: 100%;
  width: 100%;
}
.img_box img {
  height: 100%;
  width: 100%;
}
.button_container {
  margin-top: 15rem;
  width: 93%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 3rem;
  z-index: 9 !important;
  height: 1px;
}
.carousel_box img {
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.button_container button {
  background-color: none;
  border: none;
  background-color: transparent;
  outline: none;
  color: white;
}
.icon {
  font-size: 50px;
}

.inner_container_ h2 {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  -webkit-animation-duration: 6s;
  animation-duration: 6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  color: white;
  font-family: "Jost", sans-serif;
  letter-spacing: 0.5cap;
  text-align: center;
  font-weight: 400;
}
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.Custom_button{
  position: absolute;
  width: 93%;
  display: flex;
  justify-content: space-between;
  padding: 0rem 2rem;
  height: 1px;
  margin-top: -15rem;
}
.Custom_button button{
  height: 3rem;
  border: none;
  outline: none;
  opacity: 0.4;
  background-color: black;
  color: white;
}
.Custom_button button:hover{
  background-color: rgb(22, 21, 21);
  color: white;
}
.icon{
  font-size: 2rem;
}
@media (max-width:500px) {
  .carousel_box{
    height: max-content;
  }
  .inner_container_ {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: max-content;
  }
  .Custom_button{
    margin-top: -28rem;
  }
}