.main {
    padding: 20px;
    font-family: 'Arial, sans-serif';
    line-height: 1.6;
  }
  
  .header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .header h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
  }
  
  .header p {
    font-size: 1.2em;
    color: #555;
  }
  
  .section {
    margin-bottom: 40px;
  }
  
  .section h2 {
    font-size: 2em;
    margin-bottom: 15px;
    color: #333;
  }
  
  .section p {
    font-size: 1em;
    color: #666;
    margin-bottom: 15px;
  }
  
  .section ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .section ul li {
    margin-bottom: 10px;
  }
  
  .wine {
    margin-bottom: 20px;
  }
  
  .wine h3 {
    font-size: 1.5em;
    color: #444;
  }
  
  .wine p {
    font-size: 1em;
    color: #555;
  }
  
  .footer {
    text-align: center;
    margin-top: 40px;
  }
  
  .footer p {
    font-size: 1em;
    color: #777;
  }
  