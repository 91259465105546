.main{
    height: max-content;
    width: 100%;
    display: flex;
    gap: .6rem;
    padding: 1rem;
    flex-direction: column;
    color: rgb(80, 80, 80);
}
.main p{
  font-weight: 700;
}

.heading_box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .heading_box p {
    display: flex;
    gap: 0.5rem;
    font-family: Georgia, "Times New Roman", Times, serif;
    color: #7b0128;
    font-size: large;
  }
  .heading_box p hr {
    width: 3rem;
    color: #7b0128;
  }
  .heading_box h2 {
    letter-spacing: 0.2cap;
    width: 100%;
  }
  .showMoreButton{
    width: max-content;
    border:none;
    background-color: #7b0128;
    color: white!important;
    padding: 0rem .5rem;
    font-size: small;
  }
  @media screen and (max-width:784px) {
    .main{
      height: max-content;
      width: 100%;
      display: flex;
      gap: .5rem;
      padding: 1rem;
      flex-direction: column;
  }
  .main  h3{
    font-size: 2rem;
  }
  }