.container {
    max-width: 800px!important;
    margin: 0 auto!important;
    padding: 20px!important;
    background-color: #f8f8f8!important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1)!important;
    border-radius: 10px!important;
  }
  
  .formTitle {
    font-size: 24px!important;
    font-weight: bold!important;
    margin-bottom: 20px!important;
    text-align: center!important;
  }
  
  .form {
    width: 100%!important;
  }
  
  .formField {
    margin-bottom: 16px!important;
  }
  
  .continueButton {
    background-color: black!important;
    color: white!important;
    font-size: 16px!important;
    font-weight: bold!important;
    padding: 10px 0!important;
    width: 100%!important;
    margin-top: 20px!important;
  }
  
  @media (max-width: 600px) {
    .container {
      padding: 10px!important;
    }
  
    .formTitle {
      font-size: 20px!important;
    }
  
    .continueButton {
      font-size: 14px!important;
    }
  }
  