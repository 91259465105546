.main{
    height: 35rem;
    border: 1px  #7b0128 solid;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 3rem;
    gap: 5rem;
    position: relative;

}
.left_bottom_design{
    position: absolute;
    bottom: 0;
    left: 0%;
    display: flex;
    align-items: flex-start;
    justify-content:flex-start;
    overflow: hidden!important;
}

.left_bottom_design img{
    height: 15rem;
}
.right_top_design{
    position: absolute;
    top: 0;
    right: 0%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    overflow: hidden!important;
}
.right_top_design img{
    height: 15rem;
}
.heading_box{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.heading_box img{
    width: 16rem;
}
.heading_box p{
    display: flex;
    align-items: center;
    gap: .5rem;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: #7b0128 ;
}
.heading_box p hr{
    width: 3rem;
}
.heading_box  h2{
    letter-spacing: .5cap;
    /* margin-top: -.5rem; */
}
.card_box{
  display: grid;
  grid-template-columns: 1fr 1fr ;
  place-items: center;
  width: 80%;
}
.inner_container{
    display: flex;
    flex-direction: column;
    height: 20rem;
    width: 15rem;
    border: 1px  #7b0128 solid;
    align-items: center;
    gap: 1rem;
    padding: 2rem 1rem 0rem 1rem;
    cursor: pointer;

}
.inner_container div{
    text-align: center;
}
.img_box img{
    height: 180px;
}
.text_box{
    overflow: hidden;
    font-size: small;
    letter-spacing: .3cap;
}
.viewAllbtn{
    display:flex ;
    align-items: center;
    border: none;
    border: 1px  #7b0128 solid;
    padding: .5rem 1rem;
    font-size: small;
    letter-spacing: .3cap;
    background-color: white;
}
.viewAllbtn:hover{
    background-color:#7B0128;
    color: white;
}
.addBtn{
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:  #7B0128;
    color: white;
    position: relative;
    right: -6rem;
    top:-1.5rem;
    border: none;
    font-size: medium;
}
.addBtn:hover{
    background-color: rgb(159, 2, 2);
    color: white;
}
.optionsBox{
    position: absolute;
    margin-top: 7rem;

}
.options{
    display: flex;
    gap: .5rem;

}
.optionButton1 ,.optionButton2{
    border: none;
    padding: .5rem;
    height: 3rem;
    background-color:#7B0128;
    color: white;
    z-index: 999!important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.tick{
    font-size: .5rem;
}

.card_box:hover .optionButton1 {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    }
    @-webkit-keyframes fadeInUp {
    0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    }
    100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    }
    }
    @keyframes fadeInUp {
    0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    }
    100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    }
    } 
    .card_box:hover .optionButton2 {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    }
    @-webkit-keyframes fadeInUp {
    0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    }
    100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    }
    }
    @keyframes fadeInUp {
    0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    }
    100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    }
    } 
    .card_box:hover .optionButton3 {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    }
    @-webkit-keyframes fadeInUp {
    0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    }
    100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    }
    }
    @keyframes fadeInUp {
    0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    }
    100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
    }
    } 
    @media (max-width:500px) {
        .main{
            height:max-content;
            align-items: center;
            padding: 1rem 0rem;
        }
        .card_box{
            display: grid;
            grid-template-columns: 1fr ;
            gap: 5rem;
            place-items: center;
            width: 80%;
          }
          .text_box{
            text-align: left;
            height: 8rem!important;
        }
        .text_box h6{
          font-size:2rem!important;
        }
        .text_box p{
          font-size:1.3rem!important;
        }
          .inner_container{
            display: flex;
            flex-direction: column;
            height: 42rem!important;
            width: 24rem!important;
            border: 1px  #7b0128 solid;
            align-items: center;
            gap: 1rem;
            padding: 2rem 1rem 0rem 1rem;
        
        }
        .img_box{
            height: 300px!important;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
        
        }
        .img_box img{
            height: 300px!important;
        }
        .optionsBox{
            position: absolute;
            margin-top: 10rem;
          
        }
    }