.optionButton3{
    border: none;
    padding: .5rem;
    height: 3rem;
    background-color:#7B0128;
    color: white;
    z-index: 999!important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.tick{
    font-size: .5rem;
}
.container{
    height: 100vh!important;
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: hidden!important;
}
.left_box{
    height: 100vh!important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   border-right: 1px #7b0128 solid ;
   border-left: 1px #7b0128  solid;
   overflow: hidden!important;
}
.right_box{
    height: 100vh!important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden!important;
}
.img_box{
    height: 30rem; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden!important;
}
.img_box  img{
    height: 100%;
    width: 100%;
}
.des_box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0rem 2rem;
    gap: 1rem;
    height: 30rem; 
    overflow: hidden!important;
}
.des_box h6{
    font-weight: 600;
}
.input_box{
    display: flex;
    gap: .5rem;
}
.input_box input{
    width: 2.5rem;
    border: 1px #7b0128  solid;
    outline: none;
}
.input_box button{
    display:flex ;
    align-items: center;
    border: none;
    border: 1px #7b0128  solid;
    padding: .5rem 1rem;
    font-size: small;
    letter-spacing: .3cap;
    background-color: white;
}
.input_box button:hover{
    background-color: #7B0128;
    color: white;
}
.close_btn{
    position: absolute;
    top: 1rem;
    right: 1rem;
    border: none;
    background-color: none;
    background: transparent;
}
.close_btn:hover{
    color: rgb(85, 85, 85);
}
.dialog {
    height: 100vh!important;
    margin: 0rem!important;
    padding: 0rem!important;
    max-width: 100vw;
    overflow: hidden!important;
  }
  .container::-webkit-scrollbar{
    display: none;
  }
@media (max-width: 600px) {
    .container{
        height: 100vh!important;
          display: flex;
          flex-direction: column;
        overflow: hidden!important;
    }
    .left_box{
        height: 50vh!important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       border-right: 1px #7b0128  solid ;
       border-left: 1px #7b0128  solid;
       overflow: hidden!important;
    }
    .right_box{
        height: 50vh!important;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden!important;
    }
  }