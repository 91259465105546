.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .payment_summary {
    width: 100%;
    max-width: 600px;
    border: 1px solid #ddd;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  .order_summary {
    margin-top: 20px;
  }
  
  .order_item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
  }
  
  .headers {
    font-weight: bold;
  }
  
  .subtotal_box {
    text-align: right;
  }
  
  .product_item {
    display: flex;
    justify-content: space-between;
  }
  
  .calculate_ {
    text-align: right;
  }
  
  .payment_button {
    text-align: center;
    margin-top: 20px;
  }
  
  .payment_button button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .payment_button button:hover {
    background-color: #0056b3;
  }
  