.img_product_box{
    height: 16rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.img_product_box img{
    height: 100%;
}
.lists{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.container{
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.img_box_container{
    height: 30rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.img_box_container img{
    height: 100%;
}
.lists a{
    color: black!important;
}
.lists a p{
    font-size: 13px;
}