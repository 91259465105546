.main{
    height: max-content;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 3rem;
}
.blog_img_box{
    width: 100%;
    height: 30rem;
}
.blog_img_box img{
    height: 100%;
    width: 100%;
}
.container{
    width: 90%;
    height: 100%;
}
.container h6{
    font-weight: 600;
    color: #7B0128;
}
.author_box{
    display: flex;
    gap: .5rem;
    align-items: center;
}
.title_box h6{
    padding: 0rem!important;
}
.bottom_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.bottom_box p{
    color: #7B0128;
    font-weight: 600;
    font-size: .8rem;
}
.bottom_box p:hover{
    color: #54011b;
}
.bottom_box ul{
    display: flex;
    gap: .5rem;
    align-items: center;
    list-style: none; 
}
.bottom_box ul li{
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
   justify-content: center;
   align-items: center;
   background-color: #7B0128;
}
.icon{
    font-size: .8rem!important;
    color: white;
}
.bottom_box ul li:hover{
    background-color: #9c0333;
}
.read_more_link{
    text-decoration: none;
    color: #7B0128;
    color: #7B0128;
    font-weight: 600;
    font-size: .8rem;
}
.title_box h1{
    font-size: 2rem;
  }