.main {
  height: 18rem;
  width: 100%;
  border: 1px #7b0128 solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  justify-content: center;
  position: relative;
}
.heading_box p span {
  width: 3rem;
  border: none;
  border-bottom: 1px solid #7b0128; /* Solid border with desired color */
  height: 2px;
}
.right_top_design{
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}
.right_top_design img{
height: 12rem;

}
.left_top_design{
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.left_top_design img{
  height: 12rem;
}
.option_container{
  width: max-content;
}
.email{
  height: 1.6rem;
}
.option_box{
  position: absolute;
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 13rem;
  background-color: white;
  overflow-y: scroll;
  padding: 1rem;
}
.date_box{
  position: absolute;
}
.date_lable{
  width: max-content;
  min-width: 10rem;
}

.option_box::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px #ababab;
	background-color: #F5F5F5;
}

.option_box::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

.option_box::-webkit-scrollbar-thumb
{
	background-color: #ababab;
}


.option_box li{
  list-style: none;
  margin-left: -.5rem;
  
}

.heading_box {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.heading_box img {
  width: 16rem;
}
.heading_box p {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: Georgia, "Times New Roman", Times, serif;
  color: #7b0128;
  font-size: large;
}
.heading_box p hr {
  width: 3rem;
  color: #7b0128;
}
.heading_box h2 {
  letter-spacing: 0.5cap;
}
.container {
  display: flex;
  justify-content: space-evenly;
  color: #7b0128;
  align-items: center ;
  width: 90%;
}

.container label {
  display: flex;
  align-items: center;
  border: 1px #7b0128 solid;
  gap: 0.5rem;
  padding: .4rem;
  width: 100%;
}

.container label input,
select {
  border: none;
  outline: none;
  color: #7b0128;

}

.container button {

}

.submit_btn{
  display: flex;
  align-items: center;
  border: none;
  padding: 0.5rem 2rem;
  font-size: small;
  letter-spacing: 0.5cap;
  background-color: #7b0128;
  color: white;

}
.submit_btn:hover {
  background-color: #7b0128;
  color: white;
}
.date_lable{
  color: #7b7b7b;
}


@media screen and (max-width:784px) {
  .main {
    height: max-content;
    width: 100%;
    border: 1px #7b0128 solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    justify-content: center;
    padding: 3rem 0rem;

  }
  .container {
  display: grid;
  grid-template-columns: 1fr;
    gap: 2rem;
    width:100%;
    color: #7b0128;
    /* align-items: center ; */
    padding: 1rem!important;
    margin: 0rem!important;
  }
  .option_container{
    width: 100%;
  }
  .option_box{
    width: 100%;
  }
  .submit_btn{
    text-align: center;
    display: flex;
    flex-direction: column;
  }
}