.main {
  border: 1px #faa2a2 solid;
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  padding-top: 2rem;
}
.address_box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
  width: 100%;
}
.address_box div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 8rem;
  padding: 0rem 6rem;
  text-align: center;
}
.address_box div  p{
    font-weight: 600;
    letter-spacing: 0.1cap;
    font-family: "Jost", sans-serif;
    font-size: 1.2rem;
}
.address_box div  span{
    font-weight: 600;
    font-size: 1rem;
    font-family: "Jost", sans-serif;
}

.address_box div ul {
  display: flex;
  align-items: center;
  list-style: none;
  width: 100%;
  gap: 0.5rem;
  justify-content: center;
}
.address_box div ul li {
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px #7b0128 solid;
}
.icon {
  font-size: 1.2rem !important;
  border-radius: 50%;
  background-color: #7b0128;
  color: white;
  padding: 0.2rem;
}
.form_container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* border: 1px red solid; */
    width: 100%;
    padding-bottom: 6rem;


}
.form_container form{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
}
.form_container form button{
    background-color: none;
    background: transparent;
    border: 1px #faa2a2 solid;
    font-weight: 600;
    font-size: 1rem;
    font-family: "Jost", sans-serif;
    padding: .5rem 1rem;
}
.form_container form  h6{
    font-weight: 600;
    font-size: 1rem;
    font-family: "Jost", sans-serif;
    letter-spacing: 0.1cap;
}
.form_container form input{
    width: 100%;
    padding: .5rem ;
    outline: none;
    border: 1px #faa2a2 solid;
}
.message_box{
    height: 20rem;
    width: 100%;
    padding: .5rem ;
    outline: none;
    border: 1px #faa2a2 solid;
    resize: none;
}
.map_box{
  padding-top: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.map{
    width:100%;
    height:31rem ;
}

.form_container button:hover{
    background-color: #7b0128;
    color: white;
}

@media screen and (max-width:850px) {
    .form_container{
        display: grid;
        grid-template-columns: 1fr ;
        padding: 0rem!important;
    }
    .address_box{
        display: grid;
        grid-template-columns: 1fr ;
    }
    .form_container form input{
        width: 50rem;
        padding: 1rem ;
    }
    .message_box{
        width: 50rem!important;
        width: 100%;
        padding: 1rem ;
    }
    .map{
        width:80%;
        height:31rem ;
    }

}
@media screen and (max-width:500px) {
    .form_container form input{
        width: 30rem;
        padding: 1rem ;
    }
    .message_box{
        width: 30rem!important;
        width: 100%;
        padding: 1rem ;
    }
    .map{
        width:90%;
        height:31rem ;

    }
}
@media screen and (max-width:400px) {
    .form_container form input{
        width: 30rem;
        padding: 1rem ;
    }
    .message_box{
        width: 30rem!important;
        width: 100%;
        padding: 1rem ;
    }
}