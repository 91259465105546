.main{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.title_box{
    display: flex;
    align-items: center;
    gap: .5rem;
    color: #7b0128;
    width: 80%;
    text-align: center;
    justify-content: center;
}
.title_box hr{
    width: 3rem;
}
.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 80%;
}
.header p{
    width: 25rem;
}
.container {
    display: grid;
    grid-template-columns: 1fr 1fr ;
    gap: 2rem;
    width: 80%;
}