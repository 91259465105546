.main{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.container{
    display: grid;
    grid-template-columns: 1fr 1fr ;
    gap: 2rem;
}
.container div{
    border: 1px #7b0128 solid;
    height: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.icon{
    font-size: 3rem!important;
}
.container div:hover{
    background-color: #7b0128;
    color: white;
}
.container a{
    color: black;
}