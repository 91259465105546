.main{
    height: max-content;
    width: 100%;
    padding: 1rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
.container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 2rem;   
}
.img_box {
    height: 20rem;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
}
.img_box img:hover {
    transform: scale(1.1);
}
.img_box img {
    height: 100%;
    width: 100%;
    transition: transform 0.3s ease-in-out;
}


.heading_box {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .heading_box img {
    width: 16rem;
  }
  .heading_box p {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-family: Georgia, "Times New Roman", Times, serif;
    color: #7b0128;
    font-size: large;
  }
  .heading_box p hr {
    width: 3rem;
    color: #7b0128;
  }
  .heading_box h2 {
    font-family: "Futura PT", sans-serif;
    letter-spacing: 0.5cap;
    /* margin-top: -.5rem; */
  }
  @media screen and (max-width:784px) {
    .container{
        display: grid;
        grid-template-columns: 1fr ;
        width: 100%;
        gap: 2rem;   
    }
    .demo_hide{
        display: none;
    }
    .main{
        height: 35rem;
        width: 100%;
        padding: 1rem 0rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    .img_box {
        height: 30rem;
        overflow: hidden;
        transition: transform 0.3s ease-in-out;
    }
  }