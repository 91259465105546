/* src/components/CookiesBanner.module.css */
.banner {
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  max-width: 500px;
  margin: 0 auto;
  position: fixed;
  top: 18%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 999!important;
  left: 2%;
}
.banner p{
  font-size: .8rem;
}
.link{
  color: black;
  text-decoration: underline;
}
.heading{
  font-size: 1rem!important;
  font-weight: 600!important;
  margin: 0;
  padding: 0;
}

.banner button {
  margin-top: 10px;
  margin-right: 10px;
}

.Button_btn{
  background-color: #7b0128!important;
}

.Button_btn1{
  color: #7b0128!important;
  outline: 1px solid #7b0128!important;
  border:1px  #7b0128  solid !important;
}

@media (max-width: 768px) {
  .Button_btn {
    width: 100%;
    margin-right: 0;
  }

  .Button_btn1 {
    display: none;
  }
}