.main {
  height: 35rem;
  border: 1px #7b0128 solid;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  gap: 2rem;
}

a {
  text-decoration: none;
}

.heading_box {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading_box img {
  width: 16rem;
}

.heading_box p {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: Georgia, "Times New Roman", Times, serif;
  color: #7b0128;
  font-size: large;
}

.heading_box p span {
  width: 3rem;
  border: none;
  border-bottom: 1px solid #7b0128;
  height: 2px;
}

.heading_box h2 {
  letter-spacing: 0.5cap;
}

.card_box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
  overflow: hidden;
}

.inner_container {
  display: flex;
  flex-direction: column;
  height: 20rem;
  width: 15rem;
  border: 1px #7b0128 solid;
  align-items: center;
  gap: 1rem;
  padding: 0.8rem 1rem 0.5rem 1rem;
  cursor: pointer;
}

.inner_container p {
  font-weight: 600;
  letter-spacing: 0.1cap;
  font-size: 1rem;
}

.inner_container div {
  text-align: center;
}



.img_box img {
  height: 170px;
}

.text_box {
  height: 4.6rem;
  overflow: hidden;
}

.text_box p {
  font-size: 0.8rem !important;
}

.viewAllbtn {
  display: flex;
  align-items: center;
  border: none;
  padding: 0.5rem 2rem;
  font-size: small;
  letter-spacing: 0.3cap;
  background-color: #7b0128;
  color: white;
}

.viewAllbtn:hover {
  background-color: #8d0530;
  color: white;
}
.add_btn_box{
  width: 100%;
  display: flex;
   justify-content: flex-end;
   align-items: center;
}
.add_btn_box button {
  border-radius: 50%;
  height: 1.5rem!important;
  width: 1.5rem!important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7b0128;
  color: white;
  top: 0rem;
  border: none;
  font-size: medium;

}

.add_btn_box button:hover {
  background-color: #7b0128;
  color: white;
}



@media screen and (max-width: 800px) {
  .main {
    height: max-content;
  }
}
@media (max-width: 500px) {
  html {
    font-size: 10px !important;
  }

  .card_box {
    display: grid;
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .main {
    height: max-content;
  }

  .inner_container {
    display: flex;
    flex-direction: column;
    height: 42rem!important;
    width: 24rem !important;
    border: 1px #7b0128 solid;
    align-items: center;
    gap: 1rem;
  }

  .text_box {
    text-align: left;
    height: 8rem !important;
  }

  .text_box h5 {
    font-size: 2rem !important;
  }

  .text_box p {
    font-size: 1.3rem !important;
  }

  .img_box {
    height: 300px!important;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .img_box img {
    height: 300px!important;
  }
  .add_btn_box {
    height: max-content!important;
  }
  .add_btn_box button {
    border-radius: 50%!important;
    height: 25px!important;
    width: 25px!important;    
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #7b0128;
    color: white;
    border: none;
    font-size: medium;
  
  }
}
