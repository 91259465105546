.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0rem;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr ;
  width: 70%;
  gap: 1rem;
}
.form h4 {
  letter-spacing: 0.1cap;
}

.input_box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  outline: none;
  color: rgb(98, 98, 98);
  background-color: none !important;
  background: transparent !important;
}
.phone_box {
  width: 100%;
  border-radius: 4px;
  outline: none;
  color: rgb(98, 98, 98);
  background-color: none !important;
  background: transparent !important;
}
.checkbox {
  display: flex !important;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
}
.check {
  position: absolute;
  left: -31.5%;
  margin-top: 0.3rem;
}
.checkbox label {
  width: 8rem;
  margin-left: 2rem;
}
.checkbox input {
}

.check {
  width: 1rem;
}
.main button {
  background-color: none;
  color: #7b0128;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: transparent;
  width: max-content;
  font-weight: 400;
  letter-spacing: 0.1cap;
}

.main button:hover {
  background-color: #7b0128;
  color: white;
}
.all_address {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: max-content;
  gap: 2rem;
}
.address_container {
  border: 1px maroon solid;
}

.address_container div {
  width: 25rem;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}
.address_container div div {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.select_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width:500px){
  .form {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    gap: 1rem;
  }
  .all_address {
    display: grid;
    grid-template-columns: 1fr ;
    width: max-content;
    gap: 2rem;
  }
}