.main {
  height: 35rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden!important;
  position: relative;
}

.background {
  filter: brightness(70%);
  margin-top: -85rem;
}
.background img {
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  z-index: 999 !important;
  margin-left: -35rem;
  margin-top: 6rem;
  position: absolute;
}

.description_box {
  color: white;
  text-align: center;
  z-index: 999 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30rem;
  font-size: 1.4rem;
}
.description_box h3 {
  color: #f8d8d8;
  font-size: 2.5rem;
  margin-bottom: -.5rem;
}
.description_box h2 {
  font-size: 3.5rem;
  color: rgb(220, 255, 161);
}
.description_box p{
  width: 28rem;
}

.btn {
  display: flex;
  align-items: center;
  border: none;
  padding: 0.4rem 2rem;
  font-size: small;
  font-size: large;
  background-color: transparent;
  border-radius: 20px;
  background-color: white;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  color: rgb(0, 0, 0);
}
.btn:hover {
  background-color: rgb(222, 220, 220);
  color: rgb(0, 0, 0);
}
.contentmob{
  display: none;
}
.bgImagemob{
  display: none;
}

@media screen and (max-width: 500px) {
  html {
    font-size: 10px;
  }
  .btn{
    font-size: 1rem;
  }
  .main {
    height: 40rem;
   width: 100%;
    overflow: hidden;
  }
  .bgImage{
    display: none;
  }
  .bgImagemob{
    display: unset;
  }
  .background img {
    width: 100%;
  }
  .background {
    filter: brightness(70%);
    background-size: cover;
    margin-top: -180rem;
  }
  .content {
  display: none;
  }
  .contentmob{
    display: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    z-index: 999 !important;
    position: absolute;
    margin-top: 6rem;
  }
  .btn{
    margin-top: 1rem,;
  }
}
