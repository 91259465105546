.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: max-content;
  background-color: black;
  color: white;
  gap: 1rem;
}
.submit_btn{
  letter-spacing: .5cap;
  width: 8rem!important;
}
.img_box {
  height: 12rem;
  width: 25rem;
  overflow: hidden;
}
.nav_bar a {
  margin: 0 !important;
  padding-top: 0 !important;
}
.opt{
  border-right: 1px white solid;
  height: 1.1rem!important;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0rem 2rem;
  font-family: "Jost", sans-serif;
  font-size: .8rem;
  letter-spacing: .5cap;
}
.no_code{
  font-weight: 600!important;
}
.nav_bar a span {
  margin: 0rem !important;
  padding: 0rem !important;
}
.img_box img {
  height: 100%;
  width: 100%;
}
.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem;
}
.left_box {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.icon_container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.icon {
  border-radius: 50%;
  border: 1px  white solid;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.8rem;
  width: 1.8rem;
}
.icon div{
  border-radius: 50%;
  height: 1.4rem;
  width: 1.4rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

}
.instagramIcon {
  font-size: 1rem!important; /* Default font size */
}
.nav_bar {
  display: flex;
  align-items: center;
  font-size: large;
  letter-spacing: 0.2cap;
}
.nav_bar span {
  color: white;
}

.input_box {
  border: 1px rgb(255, 255, 255) solid;
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
}
.input_box input {
  width: 10rem;
  background-color: none;
  background: none;
  border: none!important;
  color: white;
  outline: none!important;
}
.input_box button {
  border: none;
  background-color: none;
  color: white;
  background-color: transparent;
  display: flex;
  align-items: center;
  width: 14rem!important;
}
.desc_box {
  text-align: center;
  width: 80%;
}

.desc_box h6 {
  font-weight: 700;
  letter-spacing: 0.5cap;
}
.payment_img_box {
  height: 2rem;
  width: 4rem;
  background-color: white;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment_img_box img {
  height: 100%;
  width: 100%;
}
.paymet_conatainer {
  display: flex;
  gap: 0.7rem;
}

@media screen and (max-width: 784px) {
  .nav_bar {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    font-size: medium;
  }
  .instagramIcon {
    font-size: 10px !important;
  }
  .icon_container {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  .opt{
    border-right: none;
  }
  .input_box {
    border: 1px rgb(255, 255, 255) solid;
    display: flex;
    gap: 0.5rem;
    padding: 0rem;
  }
  .input_box input {
    width: 200px!important;
    background-color: none;
    background: none;
    border: none!important;
    color: white;
    outline: none!important;
  }
  .input_box button {
    border: none;
    background-color: none;
    color: white;
    background-color: transparent;
    display: flex;
    align-items: center;
    width: 10rem!important;
  }
}
