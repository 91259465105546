.mainContainer {
    width: 98vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    gap: 2rem;
  }
  
  a {
    cursor: pointer;
  }
  
  .carouselBox {
    width: 80%;
  }
  
  .customButton {
    position: absolute;
    width: 90%;
    margin-top: -12rem;
    margin-left: -4.5rem;
    display: flex;
    justify-content: space-between;
    z-index: 9;
    height: 1px;
    color: black!important;

  }
  
  .customButton button {
    background: none;
    border: none;
    color: black!important;
  }
  
  .imgBox {
    width: 100%;
    cursor: pointer;
  }
  
  .imgBox img {
    width: 30rem;
    height: 20rem;
    cursor: pointer;
    margin:1rem;
  }
  
  .icon {
    font-size: 3rem !important;
    color: rgb(0, 0, 0) !important;
  }
  @media (max-width:1014px){
    .imgBox {
      width: 95%;
      cursor: pointer;
    }
    
    .imgBox img {
      width: 95%;
      width: 95%;
      height: 20rem;
      width: 24rem;
      cursor: pointer;
      margin:0rem;
    }
  }
  @media (max-width:684px) {
    .imgBox {
        width: 95%;
        cursor: pointer;
      }
      
      .imgBox img {
        width: 95%;
        height: 20rem;
        width: 24rem;
        cursor: pointer;
        margin:0rem;
      }
    
  }
  @media screen and (max-width: 500px) {
    .customButton button {
      background-color: aliceblue;
      border: none;
      opacity: 0.7;
      height: max-content;
      color: black!important;
      z-index: 9999;
      border-radius: 5px;
    }
    .imgBox {
        width: 100%;
        cursor: pointer;
      }
      
      .imgBox img {
        width: 100%;
        height: 24rem;
        cursor: pointer;
        margin:0rem;
      }
    
    .carouselBox {
      width: 100%;
    }
    
    .icon {
      font-size: 3rem !important;
      color: rgb(0, 0, 0) !important;
    }
    
    .customButton {
      position: absolute;
      width: 90%;
      margin-top: -12rem !important;
      margin-left: 1.3rem !important;
      display: flex;
      justify-content: space-between;
      z-index: 9;
      height: 1px;
      color: black!important;
    }
  }
  
 