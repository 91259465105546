.main {
  height: max-content;
  width: 100%;
  border: 1px #f5e3e3 solid;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4rem;
  padding: 3rem 2rem;
}
.product_container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 90%;
}
.description_box {
}
.img_box {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 30rem;
  border-right: 1px #7b0128 solid;
}
.img_box img {
  height: 30rem;
}
.des_box {
  padding: 0rem 2rem;
}

.input_box {
  display: flex;
  gap: 0.5rem;
}
.input_box input {
  width: 4rem;
  border: 1px #7b0128 solid;
  outline: none;
  padding: 0rem 0.5rem;
  font-size: 16px !important;
}
.input_box button {
  display: flex;
  align-items: center;
  border: none;
  border: 1px #7b0128 solid;
  padding: 0.5rem 1rem;
  font-size: small;
  letter-spacing: 0.3cap;
  background-color: white;
}
.input_box button:hover {
  background-color: #7b0128;
  color: white;
}
.icon_box {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.icon_box span {
  border-radius: 50%;
  background-color: rgb(0, 0, 0);
  color: white;
  padding: 0.2rem;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.extraInfo_btn {
  display: flex;
  border-bottom: 1px #f5e3e3 solid;
  padding: 0.3rem 1rem;
  width: 60rem;
  justify-content: space-between;
}
.extraInfo_btn h5 {
  letter-spacing: 0.2cap;
  font-size: 0.8rem;
  font-weight: 600;
}
.des_container {
  width: 60rem;
  height: max-content;
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem;
}
.des_container strong {
  color: rgb(71, 71, 71);
}
.add_info_box p {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  width: 30%;
}
.user_review_container {
  display: flex;
  gap: 1rem;
}
.user_dp {
  height: 4rem;
  width: 4rem;
  background-color: rgb(230, 230, 230);
  display: flex;
  align-items: center;
  justify-content: center;
}
.dp_icon {
  font-size: 3rem !important;
}
.user_dp img {
  height: 100%;
  width: 100%;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.form textarea {
  height: 15rem;
  padding: 0.5rem;
  outline: none;
  border: 1px #f5e3e3 solid;
  resize: none;
}
.user_input_box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
.user_input_box input {
  width: 100%;
  padding: 0.5rem;
  border: 1px #f5e3e3 solid;
}
.form button {
  display: flex;
  align-items: center;
  border: none;
  border: 1px #f5e3e3 solid;
  padding: 0.5rem 1rem;
  font-size: small;
  letter-spacing: 0.3cap;
  background-color: white;
  width: max-content;
}
.form button:hover {
  background-color: #7b0128;
  color: white;
}
.add_box_img {
  height: 12rem;
}
.add_box_img img {
  height: 100%;
  width: 100%;
}
.additional_box {
  width: 100%;
}
.inner_container {
  width: 13rem;
  height: 22rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem 1rem;
  border-left: 1px #7b0128 solid;
}
.inner_container p {
  color: #7b0128;
}
.inner_container1 p {
  color: #7b0128;
}
.inner_container1 {
  width: 13rem;
  height: 22rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem 1rem;
  border-left: none;
}
.offer_box {
  position: absolute;
  border-radius: 50%;
  background-color: #7b0128;
  color: white;
  margin-top: -2rem;
  margin-right: -10rem;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Allura", cursive;
}
.instagramIcon {
  font-size: 0.8rem !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.extra_div_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
/* src/components/GiftCardMessage.module.css */
.giftCardMessage {
  width: 100%;
}

.giftCardLabel {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.giftCardTextarea {
  width: 100%;
  height: 150px;
  padding: 10px;
  border: 1px solid #7b0128;
  border-radius: 4px;
  font-size: 16px;
  resize: none;
  outline: none;
}

@media (max-width: 784px) {
  .extra_div_container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  .product_container {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    gap: 1rem;
  }
  .product_des_box {
    display: grid;
    grid-template-columns: 1fr;
  }
  .img_box {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 30rem;
    border-right: none;
  }
  .extraInfo_btn {
    display: flex;
    border-bottom: 1px #f5e3e3 solid;
    width: 100%;
    justify-content: space-between;
  }
  .des_container {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    padding: 1rem 0rem;
  }
  .product_des_box {
    width: 100%;
  }
  .additional_box {
    display: grid;
    grid-template-columns: 1fr;
  }
  .inner_container,
  .inner_container1 {
    width: 15rem;
    height: 22rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2rem 1rem;
    border-left: none;
    margin: 1rem;
    border: 1px #7b0128 solid;
  }
  .instagramIcon {
    font-size: 8px !important;
  }
}
