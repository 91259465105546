/* src/components/CookiesSettings.module.css */
.slider {
    padding: 20px;
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .heading{
    font-size: 1rem!important;
    font-weight: 600!important;
    margin: 0;
    padding: 0;
  }
  
  
  .logo {
    height: 40px;
  }
  
  .title {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .description {
    margin-bottom: 10px;
  }
  
  .link {
    display: block;
    margin-bottom: 20px;
    color: #000;
    text-decoration: underline;
  }
  
  .button {
    margin-bottom: 20px;
    width: 100%;
  }
  
  .subtitle {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .cookieCategory {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .categoryTitle {
    flex: 1;
  }
  
  .alwaysActive {
    font-style: italic;
  }
  
  .Button_btn{
    background-color: #7b0128!important;
  }
  

  