.img_box {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 30rem;
  border-right: none;
}
.img{
  height: 30rem;
}

.button_box {
  position: fixed;
  top: 0;
  right: 2%;
}
.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button_box {
  display: flex;
  justify-content: center;
}

.button_box button {
  padding: 8px 16px;
  border: none;
  background-color: none;
  background: transparent;
  color: rgb(228, 228, 228)!important;
  cursor: pointer;
  font-size: 14px;
}
.icons{
  font-size: 2rem!important;
}
.icons:hover{
    color: rgb(255, 255, 255)!important;
}

.preview_box {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  position: absolute;
  flex-direction: column;
  left: -27%;
  background-color: rgb(255, 238, 238)!important;
}

.preview_img {
  width: 80px;
  height: 90px;
  overflow: hidden;
  border: 2px solid transparent;
  transition: border 0.3s ease;
  padding:5px ;
}

.preview_img img {
  width: 100%;
  height: 100%;
}

.preview_img.active {
  border: 2px solid #670635; /* Highlight the active preview */
}
.main{
  position: relative;
}
@media (max-width:400px){
  .preview_img {
    width: 50px;
    height: 60px;
    overflow: hidden;
    border: 2px solid transparent;
    transition: border 0.3s ease;
    padding:5px ;
  }
  .preview_box {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    position: absolute;
    flex-direction: column;
    left: -20%;
    background-color: rgb(255, 238, 238)!important;
  }
}