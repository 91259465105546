.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup_content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.popup_content h2 {
  margin-bottom: 10px;
}

.popup_content p {
  margin-bottom: 20px;
}

.button_container {
  display: flex;
  justify-content: center;
}

.button_container button {
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button_container button:hover {
  background-color: #0056b3;
}
@media (max-width:500px){
  .cookie_box{
    display: none;
  }
}