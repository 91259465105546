.btn{
   background: white; 
   box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
   z-index: 999;
  padding: .5rem;
}
.btn:hover{
    background-color: maroon;
    color: white; 
}
.cartinfo{
  text-align: center;
}
@media screen and (max-width:784px) {
  
}