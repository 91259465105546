h1,h2,h3,h4,h5,h6,p,span,button,label{
  font-family: "Jost", sans-serif;
}
input[type="text"], input[type="email"], input[type="password"] {
  font-size: 16px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  /* Adjust styles for up/down buttons (opacity, size, etc.) */
  opacity: 1;
}
@media (max-width:700px){
  p{
    font-size: 14px;
  }
  input,textarea{
    font-size: 16px;
  }
}