@font-face {
  font-family: 'BrittanySignature';
  src: url('../../Fonts/BrittanySignature.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FELIXTI';
  src: url('../../Fonts/FELIXTI.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Metropolis-Bold';
  src: url('../../Fonts/Metropolis-Bold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.main {
  height: 35rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}
.heading_box p span {
  width: 3rem;
  border: none;
  border-bottom: 1px solid #ffffff; /* Solid border with desired color */
  height: 2px;
}
.heading_box {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.heading_box img {
  width: 16rem;
}
.heading_box p {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: 'BrittanySignature';
  color: #ffffff;
  font-size: 4rem;
  font-weight: 100 !important;
}
.heading_box p hr {
  width: 3rem;
  color: #ffffff;
  font-weight: 700;
  border: 1px white solid;
}
.heading_box h2 {
  font-family: 'FELIXTI';
  letter-spacing: 0.3cap;
  text-transform: uppercase;
}
.description_box {
  color: white;
  text-align: center;
}
.description_box h2 {
  font-size: 3rem;
}

.description_box img {
  width: 30rem;
}
.background {
  filter: brightness(70%);
  margin-top: -26rem;
}
.background img {
  width: 100%;
}

.btn {
  display: flex;
  align-items: center;
  border: none;
  padding: 0.5rem 2rem;
  font-size: small;
  letter-spacing: 0.5cap;
  font-size: large;
  background-color: white;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  font-family: 'Metropolis-Bold';
}
.btn:hover {
  background-color: rgb(226, 224, 224);
  color: rgb(0, 0, 0);
}
.Parallax::-webkit-scrollbar {
  display: none;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  z-index: 999 !important;
  margin-top: -17rem;
}
.bgImagemob{
  display: none;
}
.leaf1{
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
  height: 15rem;
}
.leaf1 img{
  height: 100%;
  width: 100%;
}
.leaf2{
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 9;
  height: 10rem;
}
.leaf2 img{
  height: 100%;
  width: 100%;
}
.leaf1mob{
  display: none;
}
.contentmob{
  display: none;
}
@media screen and (max-width: 500px) {
  .contentmob{
    display: unset;
  }
  .leaf1{
    display: none;
  }
  .leaf1mob{
    display: unset;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
    height: 20rem;
    width: 20rem;
    overflow: hidden;
  }
  .leaf1mob img{
    height: 100%;
    width: 100%;
    transform: rotate(-30deg);
    margin-top: -4rem;
    margin-left: 3rem;
  }

  .leaf2{
    display: none;
  }
  .bgImage{
    display: none;
  }
  .bgImagemob{
    display: unset;
  }
  html {
    font-size: 8px;
  }
  .main {
    height: 40rem;
   width: 100%;
  }
  .btn {
    font-size: 1rem;
  }
  .background img {
    width: 100%;
  }
  .background {
    height: 120%;
    filter: brightness(70%);
    background-size: cover;
    margin-top: -80rem;
  }
  .content {
  display: none;
  }
  .contentmob{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    z-index: 999 !important;
    position: absolute;
    margin-top: 6rem;
  }
  .description_box h2 {
    font-size: 2rem;
  }
  .heading_box p {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-family: 'BrittanySignature';
    color: #ffffff;
    font-size: 3rem;
    font-weight: 100 !important;
  }
  .heading_box p hr {
    width: 3rem;
    color: #ffffff;
    font-weight: 700;
    border: 1px white solid;
  }
  .heading_box h2 {
    font-size: 2rem;
    font-family: 'FELIXTI';
    letter-spacing: 0.3cap;
    text-transform: uppercase;
  }
}
