.container {
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.addressDetails,
.noAddress {
  text-align: left;
  padding: 20px;
}
.addressDetails p{
  margin: 0;
  padding: 0;
}
.buttonContainer {
  display: flex; 
  gap: 2rem;
  margin-top: 20px;
}

.editButton,.addButton{
  font-size: small;
  cursor: pointer;
}

.submitButton,
.cancelButton {
  margin: 0 10px;
}

.form {
  margin-top: 20px;
  padding: 10px;
}

.submitButton {
  background-color: #007bff;
  color: white;
}

.cancelButton {
  border: 1px solid #007bff;
  color: #007bff;
}

.formTitle {
  font-size: 20px;
  font-weight: bold;
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: #f9f9f9;
}
