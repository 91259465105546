.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0rem;
}
.error {
  color: #7b0128;
}
.form {
   display: grid;
   grid-template-columns: 1fr 1fr;
  width: 70%;
  gap: 1rem;
}
.form h4 {
  letter-spacing: 0.1cap;
}

.input_box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form input {
  width: 100%;
  padding: 10px;
  border: 1px #f5e3e3 solid;
  border-radius: 4px;
  outline: none;
  color: rgb(98, 98, 98);
  background-color: none !important;
  background: transparent !important;
}
.phone_box {
  width: 100%;
  border-radius: 4px;
  outline: none;
  color: rgb(98, 98, 98);
  background-color: none !important;
  background: transparent !important;
}
.checkbox {
  display: flex !important;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;
}
.check {
  position: absolute;
  left: -31.5%;
  margin-top: 0.3rem;
}
.checkbox label {
  width: 8rem;
  margin-left: 2rem;
}
.checkbox input {
}

.check {
  width: 1rem;
}
.main button {
  background-color: none;
  color: #7b0128;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border: 1px #f5e3e3 solid;
  background: transparent;
  width: max-content;
  font-weight: 400;
  letter-spacing: 0.1cap;
}

.main button:hover {
  background-color: #7b0128;
  color: white;
}

.main {
  /* Add your styles */
}

.form {
  /* Add your styles */
}

.input_box {
  margin-bottom: 20px;
}

.password_input {
  display: flex;
  align-items: center;
}

.phone_box {
  width: 100%;
}

.error {
  color: red;
  font-size: 12px;
}

@media (max-width: 500px) {
  .form {
    display: grid;
    grid-template-columns: 1fr;
   width: 100%;
   gap: 1rem;
 }
  .icon {
    font-size: 40px !important;
    height: 3rem !important;
    padding: 2.2rem !important;
  }
  .phone_box {
    width: 25rem !important;
    border: 1px #ffc8da solid;
    overflow: hidden;
  }
}
