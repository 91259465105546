.input_container{
    display: flex;
    align-items: center;
    color: #7B0128;
}
.menu_item {
    color: black;
    font-family: "Jost";
    font-size: 10px!important;
    text-transform: uppercase;
  }
  
  .menu_item:hover {
    background-color: #F5E3E3!important;
  }
.input_container p{
    margin-top: 1rem;
    font-weight: 700;
}
.container h6{
    font-size: small;
    margin-top: -.5rem;
    color: #000000;
    text-align: left;
    margin-left: 0;
    padding-left: 0;
}
.container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    padding: 0rem 7rem;
}
.main{
  display: flex;
    height: 22rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F5E3E3;
    overflow: hidden;
}
.img_box{
    position: absolute;
    height: 22rem;
    overflow: hidden;
}
.img_box img{
    height: 100%;
    width: 100%;
}
.outer_container{
    display: grid;
    grid-template-columns: 1fr 1fr;
   height: 100%;
   width: 100%;
    gap: 5rem;
}
@media screen and (max-width:784px) {
    .img_box{
        display: none;
    }
    .outer_container{
         display: flex;
         flex-direction: column;
         align-items: center;
    }
    .main{
        display: flex;
          height: max-content;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #F5E3E3;
          overflow: hidden;
          padding: 3rem 1rem;
      }
      .input_container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%!important;
      }
      .input_container p{
        font-size: 1.2rem;
        font-weight: 600;
    }
    .container{
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: left;
        padding: 0rem 0rem; 
        width: 100%!important;
    }
    .menu_item {
        color: black;
        font-family: "Jost";
        font-size: 10px!important;
        text-transform: uppercase;
      }
      
      .menu_item:hover {
        background-color: #F5E3E3!important;
      }
}