.main {
  display: flex;
  flex-direction: column;
  height: max-content;
  width: 100%;
  padding: 3rem 0rem;
}
.header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  place-items: center;
  font-weight: 600;
  letter-spacing: .1cap;
  width: 100%!important;
  padding: 1rem 0rem!important;
}
.headers {
  display: grid;
  grid-template-columns: 1fr 1fr;
   padding: 1rem 0rem;
   align-items: center;
   font-weight: 500;
   width: 100%;

 }
 .subtotal_box{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4rem;
 }
.header p{
  width: max-content;
}
.img_box {
  height: 5rem;
  width: 4rem;
}
.img_box img {
  height: 100%;
  width: 100%;
}

.cart_box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  place-items: center;
  border-bottom: 1px #7b0128 solid;
  padding: 1rem 0rem;
}

.first_box {
  display: flex;
  align-items: center;
  gap: 1rem;
  letter-spacing: .1cap;
}
.quantity_box {
  width: 3.5rem;
  padding: 0rem 0rem 0rem 1rem;
  outline: none;
  border: 1px #7b0128 solid;
  color: #7b0128;
}
.del_button {
  width: 2rem;
}
.buttons_box {
  display: flex;
  justify-content: space-between;
  border-top: 1px #7b0128 solid;
  padding: 2rem 0rem;
}
.buttons_box input{
  background-color: none;
  background: transparent;
  padding: 0.3rem 1rem;
  border: 1px #7b0128 solid;
  font-family: "Jost", sans-serif;
  letter-spacing: .1cap;
  outline: none;
}
.buttons_box button {
  background-color: none;
  background: transparent;
  padding: 0.3rem 1rem;
  border: 1px #7b0128 solid;
  font-family: "Jost", sans-serif;
  letter-spacing: .1cap;
}
.buttons_box button:hover {
  background-color: #7b0128;
  color: white;
}
.buttons_box2 button {
  background-color: none;
  background: transparent;
  padding: 0.3rem 1rem;
  border: 1px #7b0128 solid;
  font-family: "Jost", sans-serif;
  letter-spacing: .1cap;
}
.buttons_box2 button:hover {
  background-color: #7b0128;
  color: white;
}
.empty_cart  button {
  background-color: none;
  background: transparent;
  padding: 0.3rem 1rem;
  border: 1px #7b0128 solid;
  font-family: "Jost", sans-serif;
  letter-spacing: .1cap;
}
.empty_cart  button:hover {
  background-color: #7b0128;
  color: white;
}
.cart_container h6 {
  font-weight: 600;
  font-family: "Jost", sans-serif;
  letter-spacing: .1cap;
}
.total_price {
  font-weight: 600;
  font-family: "Jost", sans-serif;
}

.buttons_box div {
  width: 30rem;
  display: flex;
  justify-content: space-between;

}
.empty_cart{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.empty_cart p{
  font-weight: 600;
  font-family: "Jost", sans-serif;
  letter-spacing: .1cap;
}
.cartTable table thead  tr th{
  color: #7b0128;
}
.header {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0rem;
  align-items: center;
  font-weight: 500;
  width: 80%;
}
.order_item {
  display: flex;
  justify-content: space-between;
  padding: 0rem 1rem;
  align-items: center;
  border-bottom: 1px #7b0128 solid;

}
.product_item {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0rem;
  align-items: center;
  width: 80%;
}
.product_title_{
  width: 20rem;
}
@media screen and (max-width:500px) {
  .product_title_{
    width: 20rem;
  }
  .img_box {
    height: 80px;
    width: 50px;
  }
  .img_box img {
    height: 100%;
    width: 100%;
  }
  .cartTable{
    overflow-y: scroll;
    width: 100%;
  }
  .cartTable table{
    width: max-content;
  }
  .buttons_box button {
    background-color: none;
    background: transparent;
    padding: 0rem .5rem;
    border: 1px #7b0128 solid;
    font-family: "Jost", sans-serif;
    letter-spacing: .1cap;
  }
  .updatebtn{
    width: 12rem;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    width: 1000%;
  }
  .order_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px #7b0128 solid;
  }
  .product_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .subtotal_box{
    display: flex;
    align-items: flex-end!important;
    justify-content: flex-end!important;
    padding-left: 0rem;
   }
}
