.tool_bar {
  display: flex;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: small;
  padding: 0rem 1.5rem;
  color: black;

}
.tool_bar div {
  display: flex;
  align-items: center;
}
.left_section {
  display: flex;

}

.list_option{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}
.list_option li{
text-decoration: none;
cursor: pointer;
padding: .5rem 1rem!important;
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
margin-left: -1rem!important;
}
.list_option li:hover{
  background-color: rgb(251, 235, 239);
}
.left_section {
	padding: 0;
	margin: 0;
	display: flex;
	flex-wrap: wrap;
  align-items: center;
  gap: 3rem;
  letter-spacing: .2cap;
  font-weight: 500;
  font-family: "Jost", sans-serif;
}

.left_section li {
	list-style: none;
  padding: 0;
	margin: 0;
}

.left_section li a {
	text-decoration: none;
	color: black;
	display: block;
	padding: 8px 0px;
	position: relative;
  /* border: 1px red solid; */
}

.left_section li a:before {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 2px;
	background-color: #3d3d3d;
	transform: scaleX(0);
	transition: all 0.5s;
	transform-origin: left
}

.left_section li a:hover:before, .left_section li a:hover:after {
 	transform: scaleX(1);
 }

.right_section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: 'Futura PT', sans-serif;
}
.middle_section {
  display: flex;
  align-items: center;
  justify-content: center;

}
.img_box{
    width: 8rem;
     height: 5rem;
}
.img_box img {
    height: 100%;
    width: 100%;
  }
  .icon_box{
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px black solid;
    padding: 0rem 1rem;

  }
  .icon_box2 img{
    height: 1.5rem;
    width: 1.5rem;
  }
  .icon_box2{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0rem 1rem;

  }
  .icon_box img{
    height: 1.5rem;
    width: 1.5rem;
  }
  .icon_box4{
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 0rem 1rem;
  }
  .icon_box4 img{
    height: 1rem;
    width: 2rem;
  }
  .Container{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 3rem;
    gap: 2rem;
  }

  .icon_box:hover img {
    filter: opacity(50%);
  }
  .icon_box4:hover img {
    filter: opacity(50%);
  }
  .cart_count{
    height: 1rem;
    width: 1rem;
    position: absolute;
    background-color: #7B0128 ;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .6rem;
   margin-left: 2rem;
   margin-top: -.5rem;
   z-index: 99;
  }
  
/* NavBar.module.css */
.bottom_div {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: .3rem;
  background-color: white;
  width: 13rem; /* Adjust width as needed */
  height: auto; /* Set height to auto to fit content */
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
}
.bulk_order{
  cursor: pointer;
}
.bottom_div  p{
  width: 100%;
}
.left_section li:hover .bottom_div {
  opacity: 1;
}
.category_box{
 width: 100%;
}
.option{
  cursor: pointer;
}

*{
  box-sizing: border-box;
}

.search_box{
  width: fit-content;
  height: fit-content;
  position: relative;
}
.input_search{
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 25px;
  transition: all .5s ease-in-out;
  padding-right: 40px;
  color:#000000;
}
.input_search::placeholder{
  color:rgba(0, 0, 0, 0.5);
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 100;
}
.btn_search{
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color:#ffffff ;
  background-color:transparent;
  pointer-events: painted;  
}
.btn_search:focus ~ .input_search{
  width: 15rem;
  border-radius: 0px;
  background-color: transparent;
  border-bottom:1px solid rgba(255,255,255,.5);
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 1);
}
.input_search:focus{
  width: 15rem;
  border-radius: 0px;
  background-color: transparent;
  border-bottom:1px solid rgba(255,255,255,.5);
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 1);
}
.option_box{
  height: 20rem;
  width: 20rem;
  position: absolute;
  margin-top: 24rem;
  margin-left: 0rem!important;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: .5rem;
}
.option_box::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px #ababab;
	background-color: #F5F5F5;
}

.option_box::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

.option_box::-webkit-scrollbar-thumb
{
	background-color: #ababab;
}

.option_box li{
  display: flex;
  list-style: none;
  padding: .3rem;
  cursor: pointer;

}
.price_box{
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
}
.price_box:hover{
  background-color: #f5f5f5;
}
.price_box span,h6{
  width: 100%;
  padding: 0rem 1rem;
}
.search_img_box{
  height: 3rem;
  width: 3rem;
}
.search_img_box img{
  height: 100%;
  width: 100%;
}
.search_box_mob{
  display: none!important;
}
.humburger{
  display: none!important;
}
.search_container {
  position: relative;
  display: inline-block;
}

.icon_box {
  cursor: pointer;
}

.popover {
  position: absolute;
  top: 100%; /* Adjust according to your needs */
  right: 10%;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  padding: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.popover_input {
  border: none;
  outline: none;
  padding: 5px;
  width: 250px; /* Adjust width as needed */
}

.search_button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0 5px;
}

@media screen and (max-width:1130px) {

  .tool_bar_main{
    display: flex;
    flex-direction: column;
  }
  .left_section{
    display: none;
  }
  .tool_bar{
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (max-width:784px) {
  .input_container{
    display: flex;
   justify-content: space-between;
  }
  .search_img_box_mob{
    height: 6rem!important;
  }
  .search_img_box_mob img{
    height: 100%;
  }
  .search_container{
    display: none!important;
  }
  .search_box_mob{
    border: 2px #7B0128 solid;
  }
  .humburger{
    display: unset!important;
  }
  .search_box_mob{
    display: unset!important;

  }
  .dawer{
    display: none;
  }
  .tool_bar{
    display: flex;
    justify-content: space-between;
    padding: 0rem!important;
    height: 5.5rem;
  }
  .Container{
    padding: 1.5rem;
  }
  .search_box{
    display: none!important;
  }
  .search_box_mob{
    width: 100%;
    background-color: white;
  }
  .search_box_mob input{
    width: 100%;
    padding: 1rem;
    border: none;
    outline: none;
    font-size: 16px!important;

  }
  .option_box_mob{
    height: 20rem;
    width: 90%;
    position: absolute;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding: .5rem;
    margin-top: .5rem;
    margin-left: -.2rem;

  }
  .option_box_mob::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px #ababab;
    background-color: #F5F5F5;
  }
  
  .option_box_mob::-webkit-scrollbar
  {
    width: 5px;
    background-color: #F5F5F5;
  }
  
  .option_box_mob::-webkit-scrollbar-thumb
  {
    background-color: #ababab;
  }
  
  .option_box_mob li{
    display: flex;
    align-items: center;
    list-style: none;
    padding: .3rem;
    cursor: pointer;
  }

  .price_box_mob{
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    color: black;
  }
  .price_box_mob:hover{
    background-color: #f5f5f5;
  }
  .price_box_mob span,h6{
    width: 100%;
    padding: 0rem 1rem;
    color: black;
  }
}

@media screen and (max-width:473px) {
  
  .search_box_mob{
    display: unset!important;
  }
  .dawer{
    display: none;
  }
  .tool_bar{
    display: flex;
    justify-content: space-between;
    padding: 0rem!important;
    height: 5.5rem;
  }
  .Container{
    padding: 1.5rem;
  }
  .search_box{
    display: none!important;
  }
  .search_box_mob{
    width: 100%;
    background-color: white;
  }
  .search_box_mob input{
    width: 320px;
    padding: 1rem;
    border: none;
    outline: none;
    font-size: 16px!important;
  }
  .option_box_mob{
    height: 20rem;
    width: 91.5%;
    position: absolute;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding: .5rem;
  }
  .option_box_mob::-webkit-scrollbar-track
  {
    -webkit-box-shadow: inset 0 0 6px #ababab;
    background-color: #F5F5F5;
  }
  
  .option_box_mob::-webkit-scrollbar
  {
    width: 5px;
    background-color: #F5F5F5;
  }
  
  .option_box_mob::-webkit-scrollbar-thumb
  {
    background-color: #ababab;
  }
  
  .option_box_mob li{
    display: flex;
    list-style: none;
    padding: .3rem;
    cursor: pointer;
  
  }


  .price_box_mob{
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    color: black;
  }
  .price_box_mob:hover{
    background-color: #f5f5f5;
  }
  .price_box_mob span,h6{
    width: 100%;
    padding: 0rem 1rem;
    color: black;
  }
  .search_img_box_mob{
    height: 6rem!important;
  }
  .search_img_box_mob img{
    height: 100%;
  }
}

