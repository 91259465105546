/* ImageSlider.module.css */
.mainContainer {
  width: 98vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  gap: 2rem;
}
a{
  cursor: pointer;
}
.carousel_box{
  width: 80%;
}

.custom_button{
  position: absolute;
  width: 90%;
  margin-top: -5.5rem;
  margin-left: -4.5rem;
  display: flex;
  justify-content: space-between;
  z-index: 9;
  height: 1px;
}
.custom_button button{
  background-color: none;
  background: none;
  border: none;
  color: black!important;
}
.img_box{
  height: 8rem;
  width: 16rem;
  padding: 1rem;
  background-color: rgb(255, 218, 230);
  cursor: pointer;
}
.img_box img{
  height: 100%;
  width: 100%;
  cursor: pointer;

}
.icon {
  font-size: 3rem!important;
  color: rgb(0, 0, 0)!important;
}

@media screen and (max-width:500px) {
  .img_box{
    height: 20rem;
    width: 100%;
    padding: 1rem;
    background-color: rgb(255, 218, 230);
    cursor: pointer;
  }
  .carousel_box{
    width: 80%;
  }
  .icon {
    font-size: 3rem!important;
    color: rgb(0, 0, 0)!important;
  }
  .custom_button{
    position: absolute;
    width: 100%;
    margin-top: -11rem!important;
    margin-left: -4.5rem!important;
    display: flex;
    justify-content: space-between;
    z-index: 9;
    height: 1px;
    color: black!important;
  }
}

