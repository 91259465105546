.main {
  width: 100%;
  margin: 0 auto;
  padding: 4rem 1rem;
}

.form_group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.grid_container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.main form select {
  width: 100%;
  padding: 10px;
  border: 1px #7b0128 solid;
  border-radius: 4px;
  outline: none;
  color: rgb(98, 98, 98);
}

.main button {
  background-color: none;
  color: #7b0128;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border: 1px #7b0128 solid;
  background: transparent;
  width: max-content;
  font-weight: 400;
  letter-spacing: 0.1cap;
}

.main button:hover {
  background-color: #7b0128;
  color: white;
}

.order_items {
  display: flex;
  flex-direction: column;
}
.headers {
  display: grid;
  grid-template-columns: 1fr 1fr;
   padding: 1rem 0rem;
   align-items: center;
   font-weight: 500;
   width: 100%;

 }
 .subtotal_box{
  display: flex;
  align-items: center;
  justify-content: center;
 }
.order_item {
  display: flex;
  justify-content: space-between;
  padding: 0rem 1rem;
  align-items: center;
  border-bottom: 1px #7b0128 solid;
}
.product_item {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0rem;
  align-items: center;
  width: 80%;
}
.calculate_ {
  width: 5rem;
}
.user_detail_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
.billing_details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.additional_info_box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.additional_info_box div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.additional_info_box div textarea {
  width: 100%;
  padding: 10px;
  border: 1px #7b0128 solid;
  border-radius: 4px;
  outline: none;
  height: 10rem;
}
.coupon_box {
  border: 1px #7b0128 solid;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user_detail_container h4 {
  letter-spacing: 0.1cap;
}

.coupon_field {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.coupon_field div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.info_box {
  border: 1px #7b0128 solid;
  padding: .5rem 2rem;
  text-align: center;
}

.payment_box{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.payment_box label{
  display: flex;
  align-items: center;
  gap: .5rem;
}
.enabledButton {
  background-color: green; /* Change to your desired enabled color */
  color: white;
}

.disabledButton {
  background-color: gray; /* Change to your desired disabled color */
  color: lightgray;
  cursor: not-allowed;
}
.order_notes{
  display: flex;
  flex-direction: column;
  width: 40%;
}
.coupon_field input{
  padding: 10px;
}

@media screen and (max-width:784px) {
  .grid_container{
    display: grid;
    grid-template-columns: 1fr ;
    gap: 1rem;
  }
  .user_detail_container{
    display: grid;
    grid-template-columns: 1fr ;
  }
}
.add_select{
  display: flex;
  gap: .5rem;
}