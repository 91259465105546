
.main{
    height: 35rem;
    border: 1px  #7b0128 solid;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    gap: 2rem;
}
.heading_box p span {
    width: 3rem;
    border: none;
    border-bottom: 1px solid #7b0128; /* Solid border with desired color */
    height: 2px;
  }
.heading_box{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.heading_box img{
    width: 16rem;
}
.heading_box p{
    display: flex;
    align-items: center;
    gap: .5rem;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: #7B0128 ;
    font-size: large;
}
.heading_box p hr{
    width: 3rem;
    color: #7B0128 ;
}
.heading_box  h2{
    font-family: 'Futura PT', sans-serif;
    letter-spacing: .5cap;
    /* margin-top: -.5rem; */
}
.card_box{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
}
.inner_container{
    display: flex;
    flex-direction: column;
    height: 15rem;
    width: 15rem;
    border: 8px  #7B0128 solid;
    align-items: center;
    justify-content: center;
   border-radius: 50%;
   cursor: pointer;

}
.inner_container div{
    text-align: center;
}

.text_box{
    height: 96%;
    width:96% ;
    overflow: hidden;
    font-size: small;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    letter-spacing: .4cap;
    padding: 2rem;
    background-color: #7B0128;
    color: white;
}
.text_box:hover{
    background-color: #910330;
}
.viewAllbtn{
    display:flex ;
    align-items: center;
    border: none;
    border: 1px  rgb(204, 88, 88) solid;
    padding: .5rem 1rem;
    font-size: small;
    letter-spacing: .3cap;
    background-color: white;
}
.viewAllbtn:hover{
    background-color:#7B0128;
    color: white;
}
.addBtn{
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #7B0128;
    color: white;
    position: relative;
    right: -6rem;
    top:-1.5rem;
    border: none;
    font-size: medium;
}
.addBtn:hover{
    background-color: rgb(159, 2, 2);
    color: white;
}
@media screen and (max-width:500px) {
    .main{
        height: max-content;
        border: 1px  #7b0128 solid;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 2rem;
        gap: 2rem;
    }
    .card_box{
        display: grid;
        grid-template-columns: 1fr ;
        gap: 3rem;
      }
      .text_box h5{
        font-size: .9rem;
      }
}